import useSelectors from "../../../store/selectors";
import {useSelector} from "react-redux";
import {useState} from "react";

const useHabitacion2 = () => {
    // Selectors
    const { useAuthSelectors } = useSelectors();
    const { authSelector } = useAuthSelectors();
    const auth = useSelector(authSelector);
    const [bandera, setBandera] = useState(false)

    const Animation = () => {
        setTimeout(() => {
            console.log(bandera)
            if (bandera) {
                if(document.getElementById('blokeo')){
                    document.getElementById('blokeo').style.display = 'none';
                    setBandera(false)
                }


            } else {
                if(document.getElementById('blokeo')){
                    document.getElementById('blokeo').style.display = 'block';
                    setBandera(true)
                }
            }
        }, 2000);

    }

    return {
        auth,
        Animation
    }
}

export default useHabitacion2;