import useAuthServices from "./auth";
import useResponsesServices from "./responses";

const useServices = () => {
  return {
      useAuthServices,
      useResponsesServices
  }
}

export default useServices;