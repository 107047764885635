import React, { useEffect } from "react";
import useHome from "./hook";
import "animate.css";
import {Link} from "react-router-dom";


const Home = () => {
    const {isSetTimeout} = useHome();

    isSetTimeout();
    
    return (
        <React.Fragment>

            <div className="fondo">
                <img src="images/fondo1.jpg" alt="fondo"/>
            </div>
            <Link to="/colaboradores">
                <div className="play animate__animated animate__pulse animate__infinite	">
                    <img src="images/play.png" alt="fondo"/>
                </div>
            </Link>

            <div className="ventana" id="ventana">
                <img src="images/ventana1.png" alt="ventana"/>
            </div>
            <div className="ventana2" id="ventana1">
                <img src="images/ventana2.png" alt="ventana"/>
            </div>
            <div className="ventana3" id="ventana2">
                <img src="images/ventana3.png" alt="ventana"/>
            </div>
            <div className="ventana4" id="ventana3">
                <img src="images/ventana4.png" alt="ventana"/>
            </div>
            <div className="ventana5" id="ventana4">
                <img src="images/ventana5.png" alt="ventana"/>
            </div>
            <div className="ventana6" id="ventana5">
                <img src="images/ventana6.png" alt="ventana"/>
            </div>
            <div className="ventana7" id="ventana6">
                <img src="images/ventana7.png" alt="ventana"/>
            </div>
            <div className="ventana8" id="ventana7">
                <img src="images/ventana7.png" alt="ventana"/>
            </div>

            {/* corazones */}
            <div className="corazon" id="corazon">
                <img src="images/Recurso 1.png" alt="ventana"/>
            </div>
            <div className="corazon1" id="corazon1">
                <img src="images/Recurso 2.png" alt="ventana"/>
            </div>
            <div className="corazon2" id="corazon2">
                <img src="images/Recurso 3.png" alt="ventana"/>
            </div>
            <div className="corazon3" id="corazon3">
                <img src="images/Recurso 5.png" alt="ventana"/>
            </div>
            <div className="corazon4" id="corazon4">
                <img src="images/Recurso 7.png" alt="ventana"/>
            </div>
        </React.Fragment>
    )
}

export default Home;