import React from 'react';
import useFinal from './hook';
import {Link} from "react-router-dom";

const Final = () => {
    const { array } = useFinal();

    return (
        <React.Fragment>
            <Link to="/terms-and-conditions">
                <div className="final">
                    <img src="/images/final-100.jpg" alt="final"/>
                </div>
            </Link>

            <div className="animado animate__animated animate__pulse animate__infinite">
                <img src="/images/animado.png" alt="final"/>
            </div>
            {   
                array.map((item, key) => (
                    <React.Fragment key={key}>
                        {item}
                    </React.Fragment>
                ))
            }
        </React.Fragment>
    )
}

export default Final;