import React from "react";
import {Link} from "react-router-dom";

const Colaboradores = () => {
    return (
        <React.Fragment>

                <div className="fondo-cola">
                    <img src="images/fondo-cola.jpg" alt="fondo-cola"/>
                </div>

            <Link to="/login">
                <div className="colaborar animate__animated animate__pulse animate__infinite">
                    <img src="images/colaborar.png" alt="colaborar"/>
                </div>
            </Link>

            <Link to="/registro">
                <div className="externo  animate__animated animate__pulse animate__infinite">
                    <img src="images/externo.png" alt="externo"/>
                </div>
            </Link>
        </React.Fragment>
    )
}

export default Colaboradores;