import React from 'react';
import useScreen3 from "./hook";
import {Link} from "react-router-dom";

const Screen3 = () => {
    const { Animation} = useScreen3();
    Animation()
return (
    <React.Fragment>
        <Link to="/screen4">
            <div className="screen4">
                <img src="/images/desblo2.jpg" alt="screen4"/>
            </div>
            <div className="desblo2" id="blokeo1" style={{ display: 'none' }}>
                <img src="/images/abierto.png" alt="desblo2"/>
            </div>
        </Link>
    </React.Fragment>
)
}

export default Screen3;