import React from "react";
import useQuestion2 from "./hook";

const SecondQuestion = () => {
    const {
        user,
        selectedResponse,
        selectRes
    } = useQuestion2();

    return (
        <React.Fragment>
            {
                user.gender === "Female" ? (
                    // eslint-disable-next-line
                    <img
                        src="/images/pregunt2.jpg"
                        alt="Background Image Question 1"
                        className="background-question-1"
                    />
                ) : (
                    // eslint-disable-next-line
                    <img
                        src="/images/preguntahonbre.jpg"
                        alt="Background Image Question 1"
                        className="background-question-1"
                    />
                )
            }
            <div className="true-response" onClick={() => selectedResponse(true, 'second')}>
                {
                    selectRes.response && (
                        <img src="/images/true-hover.png" alt="True Response Hover"/>
                    )
                }
            </div>
            <div className="false-response" onClick={() => selectedResponse(false, 'second')}>
                {
                    selectRes.response === false  && (
                        <img src="/images/false-hover.png" alt="False Response Hover"/>
                    )
                }
            </div>
        </React.Fragment>
    );
}

export default SecondQuestion;