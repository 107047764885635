import React from "react";
import Home from "../components/home/home";
import {Route, Routes} from "react-router-dom";
import Login from "../components/login";
import Welcome from "../components/Welcome";
import Escena1 from "../components/escena1";
import Escena2 from "../components/escena2";
import Preguntas from "../components/Preguntas";
import _ from "lodash";
import FirstQuestion from "../components/FirstQuestion";
import SecondQuestion from "../components/SecondQuestion";
import Habitacion2 from "../components/habitacion2";
import Screen2 from "../components/screen2";
import ThirtQuestion from "../components/thirtQuestion";
import FourQuestion from "../components/FourQuestion";
import Screen3 from "../components/screen3";
import Screen4 from "../components/screen4";
import FifthQuestion from "../components/fifthQuestion";
import Loader from "../components/loader";
import Final from "../components/final";
import Fallo from "../components/fallo";
import Derechos from "../components/derechos";
import Colaboradores from "../components/colaboradores";
import Registro from "../components/registro";
import Modal from "../components/Modal";

const routes = [
    {
        path: "/",
        exact: true,
        element: <Home/>
    },
    {
        path: "/login",
        exact: true,
        element: <Login/>
    },
    {
        path: "/welcome",
        exact: true,
        element: <Welcome/>
    },
    {
        path: "/escena1",
        exact: true,
        element: <Escena1/>
    },
    {
        path: "/escena2",
        exact: true,
        element: <Escena2/>
    },
    {
        path: "/preguntas",
        exact: true,
        element: <Preguntas/>
    },
    {
        path: "/habitacion2",
        exact: true,
        element: <Habitacion2/>
    },
    {
        path: "/screen2",
        exact: true,
        element: <Screen2/>
    },
    {
        path: "/screen3",
        exact: true,
        element: <Screen3/>
    },
    {
        path: "/screen4",
        exact: true,
        element: <Screen4/>
    },
    {
        path: "/loader",
        exact: true,
        element: <Loader/>
    },
    {
        path: "/final/:number",
        exact: true,
        element: <Final/>
    },
    {
        path: "/fallo/:number",
        exact: true,
        element: <Fallo/>
    },
    {
        path: "/terms-and-conditions",
        exact: true,
        element: <Derechos/>
    },
    {
        path: "/colaboradores",
        exact: true,
        element: <Colaboradores/>
    },
    {
        path: "/registro",
        exact: true,
        element: <Registro/>
    },
    {
        path: "/question-1",
        exact: true,
        element: <FirstQuestion/>
    },
    {
        path: "/question-2",
        exact: true,
        element: <SecondQuestion/>
    },
    {
        path: "/question-3",
        exact: true,
        element: <ThirtQuestion/>
    },
    {
        path: "/question-4",
        exact: true,
        element: <FourQuestion/>
    },
    {
        path: "/question-5",
        exact: true,
        element: <FifthQuestion/>
    },
    {
        path: "/modal",
        exact: true,
        element: <Modal/>
    }
]

const Router = () => {
    return (
        <Routes>
            {
                _.map(routes, (item, key) => (
                    <Route
                        path={item.path}
                        exact={item.exact}
                        key={key}
                        element={item.element}
                    />
                ))
            }
        </Routes>
    )
}

export default Router;