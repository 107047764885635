import React, { Suspense } from "react";
import {BrowserRouter} from "react-router-dom";
import Router from "./routes";
import useStore from "./store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";

const App = () => {
    const { store, persistor } = useStore();

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Provider store={store}>
                <PersistGate persistor={persistor} loading={false}>
                    <BrowserRouter>
                        <Router/>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </Suspense>
    );
};

export default App;
