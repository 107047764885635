import useTypes from "../../types";
import createReducer from "../createReducer";

const useReponsesReducers = () => {
    const {
        SET_ANSWER,
        SET_HEART
    } = useTypes();

    const responses = createReducer(
        {},{
            [SET_ANSWER](state, action){
                console.log(action)
                return {
                    ...state,
                    ...action.payload
                }
            }
        }
    );

    const heart = createReducer(
        {},{
            [SET_HEART](state, action){
                console.log(action)
                return {
                    ...state,
                    ...action.payload
                }

            }
        }
    );

    const roces = createReducer(
        {
            porcent: 1
        },
        {
            "PORCENT_DATA"(state, action){
                return {
                    ...state,
                    porcent: action.payload
                }
            }
        }
    )

    return {
        responses,
        heart,
        roces
    }
}

export default useReponsesReducers;