import React from "react";
import useScreen2 from "../screen2/hook";

const Screen2 = () => {
    const {
        Animation,
        openQuestion,
        hearts,
    } = useScreen2();
    Animation()
    return (
        <React.Fragment>
            <div className="screen" id="gif1">
                <img src="/images/cocina.jpg" alt="image"/>
            </div>
            <div className="screen" id="gif" style={{display: "none"}}>
                <img src="/images/gif.jpg" alt="gif"/>
            </div>

            {/* Hearts */}
            {
                hearts.heart3 !== undefined ? (
                    <div className="question1">
                        <img src="images/corazon.png" alt="corazon"/>
                    </div>
                ) : (
                    <div className="question1" onClick={() => openQuestion('heart3')}></div>
                )
            }
            {
                hearts.heart4 !== undefined ? (
                    <div className="question2">
                        <img src="images/corazon.png" alt="corazon"/>
                    </div>
                ) : (
                    <div className="question2" onClick={() => openQuestion('heart4')}></div>
                )
            }
        </React.Fragment>
    )
}

export default Screen2;