import useServices from "../../services";
import useTypes from "../../types";

const useAuthActions = () => {
    const {useAuthServices} = useServices();
    const {loginService, registerService} = useAuthServices();

    const { LOGIN, SET_HEART, SET_ANSWER } = useTypes();

    const actLogin = (email, onSuccess) => async dispatch => {
        try {
            const res = await loginService(email);
            const {data} = res.data;

            onSuccess && onSuccess()

            dispatch({
                type: LOGIN,
                payload: data
            })
        } catch (error) {
            console.log(error)
        }
    }

    const actLogout = async dispatch => {
        try {
            await dispatch({
                type: LOGIN,
                payload: {
                    token: undefined,
                    user: undefined
                }
            })

            await dispatch({
                type: SET_HEART,
                payload: {
                    heart1: undefined,
                    heart2: undefined,
                    heart3: undefined,
                    heart4: undefined,
                    heart5: undefined,
                }
            })

            await dispatch({
                type: SET_ANSWER,
                payload: {
                    first:undefined,
                    second:undefined,
                    thirth:undefined,
                    four:undefined,
                    fifth:undefined,
                }
            })
        }catch (e) {
            console.log(e)
        }
    }

    const actRegister = (request) => async dispatch => {
        const { onSuccess, onError, data } = request;
        try {
            await registerService(data);
            onSuccess && onSuccess();
        } catch (error) {
            onError && onError(error);
        }
    }

    return {
        actLogin,
        actRegister,
        actLogout
    }
}

export default useAuthActions;