import { useParams } from "react-router";

const useFallo = () => {
    // Params
    const { number } = useParams();

    const array = [];

    for (let i = 0; i < parseInt(number); i++) {
        array.push(
            <div className={`cora${i}`} >
                <img src="/images/corazon.png" alt="corazon" />
            </div>
        )
    }

    return {
        array,
    }
}

export default useFallo;