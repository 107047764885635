import React from "react";
import useEscena1 from "./hook";
import {Link} from "react-router-dom";
import useWelcome from "../Welcome/hook";


const Escena1 = () => {
    const {auth} = useWelcome()
    const { Animation} = useEscena1();
    Animation()
    return (
        <React.Fragment>
            <Link to="/escena2">
                {
                    auth.user?.gender === 'Female' ? (
                        <div className="fondo4">
                            <img src="images/fondo4.jpg" alt="fondo4"/>
                        </div>
                    ) : (
                        <div className="fondohom">
                            <img src="images/fondohom.jpg" alt="fondohom"/>
                        </div>
                    )
                }
            </Link>
            {/*cuadros*/}
            <div className="titulo animate__animated animate__heartBeat">
                <img src="images/titulo.png" alt="titulo"/>
            </div>
            <div className="auriculares">
                <img src="images/auriculares.png" alt="auriculares"/>
            </div>
            <div className="lente" id="lente">
                <img src="images/lente.png" alt="auriculares1"/>
            </div>
            <div className="lente1" id="lente1">
                <img src="images/lente.png" alt="auriculares2"/>
            </div>

            {/*libro*/}
            <div className="libro">
                <img src="images/libro.png" alt="libro"/>
            </div>
            <div className="lupa"  id="libro">
                <img src="images/lupita.png" alt="auriculares1"/>
            </div>

            {/*mapa*/}
            <div className="mapa">
                <img src="images/mapa.png" alt="mapa"/>
            </div>
            <div className="huella" id="mapa">
                <img src="images/huella.png" alt="huella"/>
            </div>
            <div className="userr" id="mapa1">
                <img src="images/userr.png" alt="userr"/>
            </div>
            <div className="sombra" id="mapa2">
                <img src="images/sombra.png" alt="sombra"/>
            </div>

            {/*continuar*/}
            <Link to="/escena2">
                <div className="continuar" id="continuar">
                    <img src="images/continuar.png" alt="continuar"/>
                </div>
            </Link>
        </React.Fragment>
    )
}

export default Escena1;