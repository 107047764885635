import axios from "axios";
import { useSelector } from "react-redux";
import useAuthProviders from "./auth";
import useResponsesProviders from "./responses";
import useSelectors from "../selectors";

const useProviders = () => {
    const { useAuthSelectors } = useSelectors();
    const { authSelector } = useAuthSelectors();
    const { token } = useSelector(authSelector);

    axios.defaults.baseURL = process.env.REACT_APP_API_URL
    axios.defaults.headers = {
        "Authorization": `Bearer ${token}`
    }

    return {
        useAuthProviders,
        useResponsesProviders
    };
}

export default useProviders;