import useSelectors from "../../../store/selectors";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useActions from "../../../store/actions";

const useScreen4 = () => {
    // Navigate
    const navigate = useNavigate();

    // Actions
    const { dispatch, useResponsesActions } = useActions();
    const { actSetHeart } = useResponsesActions();

    // Selectors
    const { useAuthSelectors, useResponsesSelectors } = useSelectors();
    const { authSelector } = useAuthSelectors();
    const { responsesSelector, heartSelector } = useResponsesSelectors();
    const auth = useSelector(authSelector);
    const responses = useSelector(responsesSelector);
    const hearts = useSelector(heartSelector);

    const [openModal, setOpenModal] = useState(false);
    const [question, setQuestion] = useState(0);
    const [bandera, setBandera] = useState(false);

    // Handlers
    const openQuestion = (campo) => {
        if (responses['fifth'] === undefined) {
            const obj = {
                ...hearts,
                [campo]: 5
            }
            dispatch(actSetHeart(obj))
            navigate('/question-5');
        }
    }

    useEffect(() => {
        let bandera = false;
        setInterval(() => {
            if (bandera) {
                if (document.getElementById('cuarto3')) {
                    document.getElementById('cuarto3').style.display = 'block';
                    document.getElementById('gif4').style.display = 'none';
                }
            } else {
                if (document.getElementById('cuarto3')) {
                    document.getElementById('cuarto3').style.display = 'none';
                    document.getElementById('gif4').style.display = 'block';
                }
            }

            bandera = !bandera;

        }, 2000)

        // eslint-disable-next-line
    }, [])

    return {
        auth,
        openModal,
        openQuestion,
        question,
        hearts
    }
}

export default useScreen4;