import { useParams } from "react-router";
import {useNavigate} from "react-router-dom";

const useFinal = () => {
    const navigate = useNavigate()
    // Params
    const { number } = useParams();

    const array = [];

    for(let i = 0; i < parseInt(number); i++){
        array.push(
            <div className={`coraz${i}`} >
                <img src="/images/corazon.png" alt="corazon" />
            </div>
        )
    }

    setTimeout(() => {
        navigate('/terms-and-conditions')
    }, 30000)

    return {
        array
    }
}

export default useFinal;