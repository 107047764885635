import axios from "axios"

const useResponsesProviders = () => {
    const getResponses = () => {
        return axios.get('/exam/get-responses');
    }

    const setLog = (success_responses) => {
        return axios.post('/exam/set-activity-log', {success_responses});
    }

    return {
        getResponses,
        setLog
    }
}

export default useResponsesProviders;