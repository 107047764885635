import React from "react";
import useHabitacion2 from "../habitacion2/hook";
import {Link} from "react-router-dom";

const Habitacion2 = () => {
    const {auth, Animation} = useHabitacion2();
    Animation()
    return (
        <React.Fragment>
            <Link to="/screen2">
                <div className="desblo">
                    <img src="/images/desbloeada2-100.jpg" alt="desblo2"/>
                </div>
                <div className="desblo1" id="blokeo" style={{ display: 'none' }}>
                    <img src="/images/abierto.png" alt="desblo2"/>
                </div>
            </Link>
        </React.Fragment>
    )
}

export default Habitacion2;