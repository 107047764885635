import { trackPromise } from "react-promise-tracker";
import useProviders from "../../provider";

const useResponsesServices = () => {
    const { useResponsesProviders } = useProviders();
    const { getResponses, setLog } = useResponsesProviders();

    const getResponsesServices = () => {
        return new Promise(async(resolve, reject) => {
            try {
                resolve(await trackPromise(getResponses));
            } catch (error) {
                reject(error);
            }
        });
    }

    const setLogService = (number) => {
        return new Promise(async(resolve, reject) => {
            try {
                resolve(await trackPromise(setLog(number)));
            } catch (error) {
                reject(error);
            }
        });
    }

    return {
        getResponsesServices,
        setLogService
    }
}

export default useResponsesServices;