import useSelectors from "../../../store/selectors";
import {useSelector} from "react-redux";
import {useState} from "react";
import { useForm } from "react-hook-form";
import useActions from "../../../store/actions";
import { useNavigate } from "react-router";

const useRegistro = () => {
    const navigate = useNavigate();

    // Actions
    const { dispatch, useAuthActions } = useActions();
    const { actRegister } = useAuthActions();

    // Selectors
    const { useAuthSelectors } = useSelectors();
    const { authSelector } = useAuthSelectors();
    const auth = useSelector(authSelector);
    const [bandera, setBandera] = useState(false)

    const Animation = () => {
        setTimeout(() => {
            console.log(bandera)
            if (bandera) {
                if(document.getElementById('cora123')){
                    document.getElementById('cora123').style.display = 'block';
                    setBandera(false)
                }


            } else {
                if(document.getElementById('cora123')){
                    document.getElementById('cora123').style.display = 'none';
                    setBandera(true)
                }
            }
        }, 3000);
    }

    const { control, handleSubmit } = useForm();

    const handleRegister = (data) => {
        if(data.name !== undefined && data.email !== undefined) {
            const request = {
                data: data,
                onSuccess: () => navigate('/modal'),
                onError: () => {}
            }

            dispatch(actRegister(request));
        }
    }

    return {
        auth,
        Animation,
        control, 
        handleSubmit,
        handleRegister
    }
}

export default useRegistro;