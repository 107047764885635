import React from "react";
import useWelcome from "./hook";
import {Link} from "react-router-dom";

const Welcome = () => {
    const {auth, Animation} = useWelcome();
    Animation()
    return (
        <React.Fragment>
            {
                auth.user?.gender === 'Female' ? (
                    <Link to="/escena1">
                        <div className="fondo">
                            <img src="images/welcome.jpg" alt="welcome"/>
                            <span className="text">¡Hola, {auth.user?.name} !</span>
                        </div>
                        <div className="mujer2" id="mujer2">
                            <img src="images/mujer2.png" alt="welcome"/>
                        </div>

                        <div className="mujer1" id="mujer1" style={{ display:"none" }}>
                            <img src="images/mujer1.png" alt="welcome"/>
                        </div>
                    </Link>
                ) : (
                    <Link to="/escena1">
                        <div className="fondo">
                            <img src="images/fondo-m.jpg" alt="welcome"/>
                            <span className="text">¡Hola, {auth.user?.name} !</span>
                        </div>
                        <div className="hombre" id="hombre" style={{ display:"none" }}>
                            <img src="images/hombre1.png" alt="welcome"/>
                        </div>
                        <div className="hombre1" id="hombre1">
                            <img src="images/hombre2.png" alt="welcome"/>
                        </div>
                    </Link>
                )
            }

            {/* corazones */}
            <div className="amarillo" id="amarillo">
                <img src="images/amarillo.png" alt="amarillo"/>
            </div>
            <div className="amarillo1" id="amarillo1">
                <img src="images/amarillo.png" alt="amarillo"/>
            </div>
            <div className="amarillo2" id="amarillo2">
                <img src="images/amarillo.png" alt="amarillo"/>
            </div>
            <Link to="/escena1">
                <div className="flecha" id="flecha">
                    <img src="images/flecha.png" alt="flecha"/>
                </div>
            </Link>
        </React.Fragment>
    );
}

export default Welcome;