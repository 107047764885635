import useSelectors from "../../../store/selectors";
import {useSelector} from "react-redux";
import {useState} from "react";
import Screen2 from "../index";
import {useNavigate} from "react-router-dom";
import useActions from "../../../store/actions";

const useScreen2 = () => {
    // Navigate
    const navigate = useNavigate();

    // Actions
    const { dispatch, useResponsesActions } = useActions();
    const { actSetHeart } = useResponsesActions();

    // Selectors
    const { useAuthSelectors, useResponsesSelectors } = useSelectors();
    const { authSelector } = useAuthSelectors();
    const { responsesSelector, heartSelector } = useResponsesSelectors();
    const auth = useSelector(authSelector);
    const responses = useSelector(responsesSelector);
    const hearts = useSelector(heartSelector);

    const [openModal, setOpenModal] = useState(false);
    const [question, setQuestion] = useState(0);
    const [bandera, setBandera] = useState(false);
    const [cor, setCor] = useState({});

    // Handlers
    const openQuestion = (campo) => {
        if(responses['thirth'] === undefined){
           const obj = {
               ...hearts,
               [campo]: 3
           }
            dispatch(actSetHeart(obj))
            navigate('/question-3');
        }else{
            const obj = {
                ...hearts,
               [campo]: 4
            }
            dispatch(actSetHeart(obj))
            navigate('/question-4');
        }
    }

    const Animation = () => {
        setTimeout(() => {
            console.log(bandera)
            if (bandera) {
                if(document.getElementById('gif1')){
                    document.getElementById('gif1').style.display = 'none';
                    document.getElementById('gif').style.display = 'block';
                    setBandera(false)
                }


            } else {
                if(document.getElementById('gif1')){
                    document.getElementById('gif1').style.display = 'block';
                    document.getElementById('gif').style.display = 'none';
                    setBandera(true)
                }
            }
        }, 2000);

    }

    return {
        auth,
        Animation,
        openModal,
        openQuestion,
        question,
        hearts
    }
}

export default useScreen2;