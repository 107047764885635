import useProviders from "../../provider";
import {trackPromise} from "react-promise-tracker";

const useAuthServices = () => {
    const {useAuthProviders} = useProviders();
    const {login, register} = useAuthProviders();

    const loginService = (email) => {
        return new Promise(async (resolve, reject) => {
            try {
                resolve(await trackPromise(login(email)))
            } catch (error) {
                reject(error)
            }
        })
    }

    const registerService = (data) => {
        return new Promise(async(resolve, reject) => {
            try {
                resolve(await trackPromise(register(data)));
            } catch (error) {
                reject(error);
            }
        })
    } 

    return {
        loginService,
        registerService
    }
}

export default useAuthServices;