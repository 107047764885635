import {combineReducers} from "@reduxjs/toolkit";
import useAuthReducers from "./auth";
import useResponsesReducers from "./responses";

const useReducers = () => {
    const {auth} = useAuthReducers();
    const {
        responses,
        heart,
        roces
    } = useResponsesReducers();

    return combineReducers(
        Object.assign({
            auth,
            responses,
            heart,
            roces
        })
    )
}

export default useReducers;