import React from "react";
import useHome from "../home/hook";
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import useLogin from "./hook";
import {Controller} from "react-hook-form";
import {Button} from "@mui/material";

const Login = () => {
    const {isSetTimeout} = useHome();
    isSetTimeout();

    const { control, handleLogin, handleSubmit } = useLogin();

    const InputComponent = (controlInput) => {
        const { field } = controlInput;
        console.log(field)
        return (

        <Input
            id="input-with-icon-adornment"
            placeholder="Correo electronico"
            type="email"
            name={field.name}
            value={field.value}
            ref={field.ref}
            onChange={(e) => {
                field.onChange(e);
            }}
            startAdornment={
                <InputAdornment position="start">
                    <img src="images/user.png" alt="user"/>
                </InputAdornment>
            }
        />
    )}

    return (
        <React.Fragment>
            <div className="fondo2">
                <img src="images/fondo2.jpg" alt="fondo2"/>
            </div>
            <div className="ventana" id="venta">
                <img src="images/ventana1.png" alt="ventana"/>
            </div>
            <div className="ventana2" id="venta1">
                <img src="images/ventana2.png" alt="ventana"/>
            </div>
            <div className="ventana3" id="venta2">
                <img src="images/ventana3.png" alt="ventana"/>
            </div>
            <div className="ventana4" id="venta3">
                <img src="images/ventana4.png" alt="ventana"/>
            </div>
            <div className="ventana5" id="venta4">
                <img src="images/ventana5.png" alt="ventana"/>
            </div>
            <div className="ventana6" id="venta5">
                <img src="images/ventana6.png" alt="ventana"/>
            </div>
            <div className="ventana7" id="venta6">
                <img src="images/ventana7.png" alt="ventana"/>
            </div>
            <div className="ventana8" id="venta7">
                <img src="images/ventana7.png" alt="ventana"/>
            </div>

            {/* corazones */}
            <div className="corazo" id="cora">
                <img src="images/corazon.png" alt="corazon"/>
            </div>

            {/* input */}
            <FormControl variant="standard" className="text-login">
                <Controller
                    control={control}
                    name="email"
                    render={InputComponent}
                />
            </FormControl>

            {/* Boton */}
            <Button className="boton" onClick={handleSubmit(handleLogin)}>
                <img src="images/boton.png" alt="Button Login" />
            </Button>
        </React.Fragment>
    )
}

export default Login;