import React from 'react';
import {Link} from "react-router-dom";

const Derechos = () => {
  return (
      <React.Fragment>
          <Link to="/">
              <div className="derechos">
                  <img src="/images/derechos.jpg" alt="derechos"/>
              </div>
          </Link>

      </React.Fragment>
  )
}

export default Derechos;