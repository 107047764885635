import React, {useEffect} from 'react';
import useScreen4 from "./hook";

const Screen4 = () => {;
    const {
        hearts,
        openQuestion
    } = useScreen4();

    return (
        <React.Fragment>
            <div className="cuarto3" id="cuarto3">
                <img src="/images/cuarto3.jpg" alt="cuarto3"/>
            </div>
            <div className="cuarto3" id="gif4" style={{display: "none"}}>
                <img src="/images/cuartogif.jpg" alt="gif"/>
            </div>

            {/* Hearts */}
            {
                hearts.heart5 !== undefined ? (
                    <div className="question5">
                        <img src="images/corazon.png" alt="corazon"/>
                    </div>
                ) : (
                    <div className="question5" onClick={() => openQuestion('heart5')}></div>
                )
            }
        </React.Fragment>
    );
}

export default Screen4;