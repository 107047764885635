import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import useActions from "../../../store/actions"
import useSelectors from "../../../store/selectors"

const useLoader = () => {
    // History
    const navigate = useNavigate();

    // Actions
    const { dispatch, useResponsesActions } = useActions();
    const { actSetInterval, actGetResponses, actSetLog } = useResponsesActions();

    // Selectors
    const { useResponsesSelectors } = useSelectors();
    const { responsesSelector } = useResponsesSelectors();
    const responses = useSelector(responsesSelector);
    
    // Constant
    const [width, setWidth] = useState(0);

    const getNumberResponses = (data) => {
        let number = 0;
        const array = [];
        Object.values(responses).map((item, index) => {
            if(index === 0 && item === data.first){
                array.push(item);
            }else if(index === 1 && item === data.second){
                array.push(item);
            }else if(index === 2 && item === data.third){
                array.push(item);
            }else if(index === 3 && item === data.fourth){
                array.push(item);
            }else if(index === 4 && item === data.fifth){
                array.push(item);
            }
        })

        return array.length;
    }

    let number = 1;

    useEffect(() => {

        const id = setInterval(() => {
            if(number === 100) {
                
                clearInterval(id);
            }else if(number === 99){
                dispatch(actGetResponses((data) => {
                    const number = getNumberResponses(data)
                    dispatch(actSetLog(number, () => {
                        if(number < 4) {
                            navigate(`/fallo/${number}`);
                        }else{
                            navigate(`/final/${number}`);
                        }
                        
                    }));
                }));
            }
            setWidth(29 * number / 100);
            number++
        }, 50);
        // eslint-disable-next-line
    }, [])

    return {
        width,
    };
}

export default useLoader;